.profile{
    width: 50%;
    height: 70vh;
    display: flex;
    padding: 60px;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
}


.listSpan {
    font-size: 2.5rem;
    width: fit-content;
    
}

.listSpan .animationHome {
    display: inline-block;
    animation: bounce 3s ease;
    text-transform: uppercase;
}

.listSpan .animationHome:nth-child(2) {
    animation-delay: 0.2s;
}

.listSpan .animationHome:nth-child(3) {
    animation-delay: 0.4s;
}

.listSpan .animationHome:nth-child(4) {
    animation-delay: 0.6s;
}

.listSpan .animationHome:nth-child(5) {
    animation-delay: 0.8s;
}

.listSpan .animationHome:nth-child(6) {
    animation-delay: 1s;
}

.listSpan .animationHome:nth-child(7) {
    animation-delay: 1.2s;
}

.listSpan .animationHome:nth-child(8) {
    animation-delay: 1.4s;
}


@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(-2rem);
    }

    20% {
        transform: translateY(0);
    }
}

.containerTP
{
    height: 60vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 5px;
}

.frontend{
    text-transform: uppercase;
    font-size: 2.5rem;
    color: #088A68;
}

.chamada{
    width: 80%;
    margin-top: 35px;
}


@media only screen and (max-width: 767px ) {
    .profile{
        width: 100%;
        padding: 10px;
    }

    .chamada{
        width: 100%;
        margin: 10px 0 0 0 ;
    }
}