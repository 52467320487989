* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;}

html,
body,
#root {
  height: 100%;
  color: #A4A4A4;
  background: #070719;
  /* -webkit-linear-gradient(rgba(135, 60, 255, 1), rgba(135, 60, 255, 0.0) 0%),
  -webkit-linear-gradient(-180deg, #efefef 50%, #0B0B3B 0%);  */
}