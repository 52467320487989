.home {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 50%;
    height: 70vh;
    padding: 10px;
    overflow: scroll;
}

.cardResumo{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 20px;
}

.cardResumo h1{
    width: fit-content;
    background-color: #088A68;
    color: #070719;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 10px;
}

.home::-webkit-scrollbar {
    display: none;
  }

  .home {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


.resumo{
    margin-bottom: 30px;
}

.cardAcademy{
    width: 100%;
    height: auto;
    padding: 20px;
    margin-bottom: 20px;
}


.cardAcademy li{
    margin: 10px 10px 10px 0;
    width: fit-content;
    list-style: none;
    padding: 5px;
    border-radius: 5px;
    color: #088A68;  
    border: 1px solid #088A68;
    font-size: 12px;
    letter-spacing: 5px;
}

.cardAcademy ul{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.cardAcademy h2{
    width: 80%;
    margin-bottom: 20px;
}


@media only screen and (max-width: 767px ) {
    .home{
        width: 100%;
        padding: 0;
        overflow: none;
        height: auto;
    }
    .resumo, .cardAcademy{
        width: 100%;
        margin-bottom: 10px;
    }

    .resumo{
        margin-bottom: 20px;
    }

    .cardResumo{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cardResumo h1{
        width: fit-content;
        padding: 10px;
    }

    
}