
.nav{
    width: fit-content;
    height: auto;
    border-bottom: 1px solid #088A68;
    padding: 20px 0;
}

.links{
    font-size: 26px;
    color: #A4A4A4;
    margin-right: 10px;
}

.links:hover{
    color: #088A68;
}
