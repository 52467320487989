.nav{
   width: 100%;
   height: 70px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.caixaLogo{
   width: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.name{
   border-radius: 5px;
   padding: 3px;
   /* border: 1px solid #A4A4A4; */
   color: #A4A4A4;
   margin-left: 3px;
}

.logo{
   width: fit-content;
   border-radius: 5px;
   font-size: 1em;
}


.ul{
   width: 50%;
   display: flex; 
   justify-content: flex-end;
   list-style: none;
   padding: 5px 0 5px 0;
}

.list{
   width: 200px;
   margin-right: 5px;
   text-align: center;
}

.item{
    text-decoration: none;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: 3px;
    color: #A4A4A4;
}


.list:hover .item span{
   transition-duration: 1s;
   letter-spacing: 10px;
}


@media only screen and (max-width: 767px ) {
  .nav{
   width: 100%;
   display: flex;
   justify-content: space-around;
   align-items: center;
  }

  .caixaLogo{
   display: none;
  }
  
  .ul{
   width: 100%;
  }

  .list:hover .item span{
   transition-duration: 1s;
   letter-spacing: 10px;
   background-color: transparent;
   padding: 3px;
   border: none;
   border-radius: 5px;   
   color: #A4A4A4;
   }

  
}
