.containerTecnologia{
    width: 50%;
    height: 70vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow: scroll;
}
.containerTecnologia::-webkit-scrollbar {
    display: none;
  }

  .containerTecnologia {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


.card{
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;
}

.card h1{
    background-color: #088A68;
    color: #070719;
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.card:hover{
    
    transition-duration: 1s;
    box-shadow: rgba(50, 50, 93, 0.25) 50px 50px 50px 50px inset, rgba(0, 0, 0, 0.3) 50px 50px 50px 50px inset;
    cursor: pointer;
}


.h3{
    margin-top: 20px;
}

.introducao{
    margin: 20px 0;
}
.list{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
}


@media only screen and (max-width: 767px ) {
    .containerTecnologia{
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column;
        overflow: none;
        padding: 0;
    }

    .card{
        width: 100%;
        margin: auto 0;
        padding: 10px;
    }
}