.container{
    display: flex;
    width: 100%;
    height: 85vh;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 20px;
}

@media only screen and (max-width: 767px ) {
    .container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
}